import { css } from "@emotion/react";
import Popup from "reactjs-popup";
import styled from "styled-components";
import { useRef } from "react";

import useKakaoShare from "../../hooks/useKakaoShare";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import LectureOrderBody from "./LectureOrderBody";
import { doCopy, setUtm, setReturnSession, addComma } from "../../utils";
import { color } from "../../tailwindStyle";

import closeIcon from "../../assets/icons/close.svg";
import kakaoIcon from "../../assets/icons/kakao.svg";
import messageIcon from "../../assets/icons/message.svg";
import shareLecture from "../../assets/icons/share_lecture.svg";

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const popupModalProps = {
  modal: true,
  contentStyle: {
    width: "100%",
    background: "white",
    maxWidth: "500px",
    overflow: "auto",
    paddingTop: "25px",
    paddingBottom: "33px",
    paddingLeft: "35px",
    paddingRight: "35px",
    borderRadius: "12px",
  },
  overlayStyle: {
    background: "rgba(0, 0, 0, 0.75)",
  },
};

const sideBarStyle = css`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    position: fixed !important;
    width: 286px !important;
    padding: 1rem;
    left: 1300px;
    top: 100px;
    background-color: rgb(250, 251, 252);
    border: 1px solid ${color.gray300};
  }
`;

const SideBar = ({ lecture, hasPurchased, slug }) => {
  const copyUrlRef = useRef(null);
  const { isUserSignedIn } = useUserSignedIn();
  const tempLectureId = 80;
  const LinkBtn = ({ text, onClickLink, ...rest }) => {
    return (
      <button
        type="button"
        className={`flex items-center justify-center w-full text-white rounded-md cursor-pointer whitespace-nowrap h-[53px] bg-black text-[15px] font-[700] mt-[10px]`}
        onClick={(e) => {
          e.preventDefault();
          location.href = onClickLink;
        }}
        {...rest}
      >
        {text}
      </button>
    );
  };

  const ShareBtn = () => {
    return (
      <StyledPopup
        modal
        nested
        lockScroll
        trigger={
          <button
            className="flex items-center justify-center w-full bg-white rounded-md h-[52px] text-[15px] font-[700] mt-[10px]"
            id="pcExternalSharing"
          >
            <img
              src={shareLecture}
              alt="공유아이콘"
              className="w-[14px] h-[16px] mr-[12px]"
            />
            <span className="pt-[3px]">공유하기</span>
          </button>
        }
        position="right center"
      >
        {(close) => (
          <div className="w-[400px] pt-[24px] px-[10px] pb-[38px] bg-white flex flex-col rounded-[10px]">
            <div className="flex justify-between px-3 border-b border-gray-400">
              <span className="pb-4 font-bold">공유하기</span>
              <img
                src={closeIcon}
                alt="공유하기끄기버튼"
                className="w-4 h-4 cursor-pointer"
                onClick={close}
              />
            </div>
            <div className="flex items-center pt-6 justify-evenly">
              <div
                className="flex flex-col items-center cursor-pointer"
                onClick={() => useKakaoShare(lecture)}
              >
                <img
                  src={kakaoIcon}
                  alt="카카오톡아이콘"
                  className="w-[51px] h-[51px]"
                />
                <textarea
                  ref={copyUrlRef}
                  value={window.location.href}
                  className="hidden"
                  readOnly
                ></textarea>
                <span className="text-xs text-black">카카오톡</span>
              </div>
              <div
                className="flex flex-col items-center cursor-pointer"
                onClick={() => {
                  const utmObj = {
                    utm_source: "externalsharing",

                    utm_medium: "viral",
                    utm_campaign: "",
                  };
                  doCopy(setUtm(utmObj));
                }}
              >
                <img
                  src={messageIcon}
                  alt="URL복사아이콘"
                  className="w-[51px] h-[51px]"
                />
                <span className="text-xs text-black">URL 복사</span>
              </div>
            </div>
          </div>
        )}
      </StyledPopup>
    );
  }

  return (
    <div
      css={sideBarStyle}
      className="w-full sticky top-[42px] left-[0px] self-start py-[40px] px-[30px] z-50 bg-[#F9F9F9] rounded-[10px] flex flex-col mt-[30px]"
    >
      <span className="text-[22px] font-[700] leading-[30px]">
        {lecture?.title}
      </span>

      {lecture?.id == tempLectureId && (
        <>
          <div className="flex flex-col">
            <div onClick={() => {
              window.location.href =
                "https://smartstore.naver.com/smallbigclass/products/9542709315";
            }} className="cursor-pointer flex items-center justify-center w-full text-white rounded-md whitespace-nowrap h-[53px] bg-blue-base class_buy_now text-[15px] font-[700] mt-[10px]">
              구매하기
            </div>
            <ShareBtn />
          </div>
        </>
      )}

      {lecture?.id != tempLectureId && !lecture?.is_purchase && (
        <div className="flex flex-col">
          <div className="mt-4 rounded-[5px] bg-[#999999] flex items-center justify-center text-white h-[52px] font-bold">
            {lecture?.viewer_type === "dnav"
              ? "수강 인원 마감"
              : "구매가 불가능한 상품입니다."}
          </div>
          <ShareBtn />
        </div>
      )}

      {lecture?.id != tempLectureId && lecture?.is_purchase && (
        <>
          {lecture?.discount_rate !== "0.0" && (
            <div className="flex items-center mt-[20px]">
              <span className="mr-2 text-lg line-through text-gray88">
                {addComma(Math.round(lecture?.before_price))}원
              </span>
              <span className="text-blue-base text-lg font-[700]">
                {Math.round(lecture?.discount_rate)}%
              </span>
            </div>
          )}
          <div className="flex items-center mt-[10px]">
            <span className="mr-1 font-[800] text-[32px]">
              {addComma(Math.round(lecture?.price))}
            </span>
            <span className="font-[500] text-[22px]">원</span>
          </div>

          <div className="border-b border-[#B2B2B2] mt-[20px] mb-[10px]"></div>

          {isUserSignedIn ? (
            hasPurchased?.has_authority && lecture?.viewer_type !== "dnav" ? (
              // 로그인 완료 권한 있음
              <div className="flex flex-col">
                {(lecture?.has_kit || lecture?.has_textbook) && (
                  <Popup
                    {...popupModalProps}
                    modal
                    trigger={
                      <div className="cursor-pointer flex items-center justify-center w-full text-white rounded-md whitespace-nowrap h-[53px] bg-blue-base class_buy_now text-[15px] font-[700] mt-[10px]">
                        배송품 구매하기
                      </div>
                    }
                    nested
                  >
                    {(close) => (
                      <LectureOrderBody
                        {...lecture}
                        close={close}
                        slug={slug}
                        orderableType="Lecture"
                      />
                    )}
                  </Popup>
                )}
                <LinkBtn
                  text="이어서 학습하기"
                  onClickLink={hasPurchased?.next_lesson_link}
                />
              </div>
            ) : (
              <Popup
                {...popupModalProps}
                modal
                trigger={
                  <div className="cursor-pointer flex items-center justify-center w-full text-white rounded-md whitespace-nowrap h-[53px] bg-blue-base class_buy_now text-[15px] font-[700] mt-[10px]">
                    강의 구매하기
                  </div>
                }
                nested
              >
                {(close) => (
                  <LectureOrderBody
                    {...lecture}
                    close={close}
                    slug={slug}
                    orderableType="Lecture"
                  />
                )}
              </Popup>
            )
          ) : (
            // 로그인 미완료 구매 불가능
            <div
              className="cursor-pointer flex items-center justify-center w-full text-white rounded-md whitespace-nowrap h-[53px] bg-blue-base class_buy_now text-[15px] font-[700] mt-[10px]"
              onClick={() => setReturnSession()}
            >
              강의 구매하기
            </div>
          )}
          <ShareBtn />
        </>
      )}
    </div>
  );
};

export default SideBar;
