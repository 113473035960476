import receiptIcon from "../../assets/icons/receipt.svg";
import { addComma } from "../../utils";
import moment from "moment";
import DetailBox from "./DetailBox";
import ResultBottomButton from "./ResultBottomButton";
import RecommendLectures from "../shared/RecommendLectures";

const OrderResultInfo = ({ order, orderable }) => {
  return (
    <div className="max-w-[640px] min-w-[330px] w-full mx-auto pt-[100px]">
      <div className="mx-[15px]">
        <img src={receiptIcon} alt="영수증" className="mx-auto w-[74px]" />
        <div className="font-bold mt-[23px] mx-auto text-center">
          결제가 완료되었습니다
        </div>
        <div className="mt-[24px] border-gray100 border rounded-[12px] py-[37px] px-[30px] text-sm flex">
          <div className="flex flex-col min-w-[55px] text-gray400 mr-[28px]">
            <span>카드사</span>
            <span className="mt-[17px]">할부개월</span>

            <span className="mt-[17px]">결제 금액</span>

            <span className="mt-[17px]">결제 일시</span>
          </div>
          <div className="flex flex-col font-medium">
            <span>{order?.card?.cardName}</span>
            <span className="mt-[17px]">
              {order?.card?.cardQuota === 0
                ? "일시불"
                : `${order?.card?.cardQuota}개월`}
            </span>
            <span className="mt-[17px] text-blue-base">
              {addComma(Number(order?.payment_amount))} 원
            </span>
            <span className="mt-[17px]">
              {moment(order?.payment_created_at).format(
                "YYYY년 MM월 DD일 HH:mm"
              )}
            </span>
          </div>
        </div>
        <DetailBox order={order} orderable={orderable} />
        <ResultBottomButton order={order} />
        <RecommendLectures className="mt-[47px]" />
      </div>
    </div>
  );
};

export default OrderResultInfo;
