import ReviewNewSuccess from "../components/review/ReviewNewSuccess";
import RecommendLectures from "../components/shared/RecommendLectures";

const ReviewResult = ({ lectureId, lectureType, viewerType }) => {
  return (
    <div className="max-w-[640px] mx-auto">
      <ReviewNewSuccess
        viewerType={viewerType}
        lectureType={lectureType}
        lectureId={lectureId}
      />

      <RecommendLectures
        lectureId={lectureId}
        className="mt-[30px] mobile:px-[15px]"
      />
    </div>
  );
};

export default ReviewResult;
