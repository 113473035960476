import { css } from "@emotion/react";
import { useContext } from "react";

import { DrawerContext } from "../../pages/LectureShow";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import { color } from "../../tailwindStyle";
import { addComma, setReturnSession } from "../../utils";

const FooterMobile = ({ lecture, hasPurchased }) => {
  const { openSheetDrawer } = useContext(DrawerContext);
  const { isUserSignedIn } = useUserSignedIn();
  const tempLectureId = 80;

  const LinkBtn = ({ text, onClickLink, ...rest }) => {
    return (
      <button
        href={onClickLink}
        className="flex items-center justify-center w-full px-2 text-xs font-bold text-white border border-white rounded-sm lectureBtn"
        css={css`
          white-space: nowrap;
        `}
        onClick={(e) => {
          e.preventDefault();
          location.href = onClickLink;
        }}
        {...rest}
      >
        {text}
      </button>
    );
  };

  const footerStyle = css`
    position: fixed;
    bottom: 0px;
    height: 60px;
    width: 100vw;
    background-color: ${color["blue-base"]};
    padding: 13px 3.75vw;
    z-index: 8;
  `;

  const buttonGroupStyle = css`
    .lectureBtn {
      margin-left: 0.5rem;
    }

    button + button {
      margin-left: 0.5rem;
    }
    height: 100%;
    width: 100%;
    max-width: ${hasPurchased?.has_authority ? `none` : `160px`};
  `;

  return (
    <div css={footerStyle} className="flex items-center justify-between">
      {lecture?.id == tempLectureId && (
        <>
          {!hasPurchased?.has_authority && (
            <div className="flex flex-col">
              {lecture?.discount_rate !== "0.0" && (
                <div className="flex items-center">
                  <span
                    className="mr-2 text-xs line-through"
                    style={{ color: "#ffea00" }}
                  >
                    {addComma(Math.round(lecture?.before_price))}원
                  </span>
                  <span className="text-xs text-white">
                    {Math.round(lecture?.discount_rate)}%
                  </span>
                </div>
              )}
              <span className="font-bold text-white">
                {addComma(Math.round(lecture?.price))}원
              </span>
            </div>
          )}
          <div div css={buttonGroupStyle} className="flex justify-between">
            <button
              onClick={() => {
                window.location.href =
                  "https://smartstore.naver.com/smallbigclass/products/9542709315";
              }}
              className="flex items-center justify-center w-full px-2 text-xs font-bold text-white border border-white rounded-sm class_buy_now lectureBtn"
              css={css`
                white-space: nowrap;
              `}
            >
              구매하기
            </button>
          </div>
        </>
      )}

      {lecture?.id != tempLectureId && !lecture?.is_purchase && (
        <div className="flex items-center mx-auto text-sm font-bold text-white justify-cneter">
          {lecture?.viewer_type === "dnav"
            ? "수강 인원 마감"
            : "구매가 불가능한 강의 입니다."}
        </div>
      )}

      {lecture?.id != tempLectureId && lecture?.is_purchase && (
        <>
          {!hasPurchased?.has_authority && (
            <div className="flex flex-col">
              {lecture?.discount_rate !== "0.0" && (
                <div className="flex items-center">
                  <span
                    className="mr-2 text-xs line-through"
                    style={{ color: "#ffea00" }}
                  >
                    {addComma(Math.round(lecture?.before_price))}원
                  </span>
                  <span className="text-xs text-white">
                    {Math.round(lecture?.discount_rate)}%
                  </span>
                </div>
              )}
              <span className="font-bold text-white">
                {addComma(Math.round(lecture?.price))}원
              </span>
            </div>
          )}

          <div css={buttonGroupStyle} className="flex justify-between">
            {hasPurchased?.has_authority ? (
              <>
                {lecture?.has_kit && (
                  <button
                    onClick={openSheetDrawer}
                    className="flex items-center justify-center w-full px-2 text-xs font-bold text-white border border-white rounded-sm lectureBtn class_buy_now"
                    css={css`
                      white-space: nowrap;
                    `}
                  >
                    키트 구매하기
                  </button>
                )}
                {!lecture?.has_kit && lecture?.has_textbook && (
                  <button
                    onClick={openSheetDrawer}
                    className="flex items-center justify-center w-full px-2 text-xs font-bold text-white border border-white rounded-sm lectureBtn class_buy_now"
                    css={css`
                      white-space: nowrap;
                    `}
                  >
                    교재 구매하기
                  </button>
                )}
                <LinkBtn
                  text="이어서 학습하기"
                  onClickLink={lecture?.next_lesson_link}
                />
              </>
            ) : (
              <button
                onClick={
                  isUserSignedIn ? openSheetDrawer : () => setReturnSession()
                }
                className="flex items-center justify-center w-full px-2 text-xs font-bold text-white border border-white rounded-sm class_buy_now lectureBtn"
                css={css`
                  white-space: nowrap;
                `}
              >
                클래스 신청하기
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FooterMobile;
