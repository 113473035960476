import { useState, useEffect, useContext, useRef } from "react";
import { css } from "@emotion/react";
import { Link, Element } from "react-scroll";
import { useCookies } from "react-cookie";

import Lightbox from "react-image-lightbox";
import { constant, times, slice, filter } from "lodash";
import moment from "moment";
import Swal from "sweetalert2";

import { getCurriculums, getReviews, postEncrypt } from "../../api";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import { addComma, setReturnSession } from "../../utils";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import { DrawerContext } from "../../pages/LectureShow";
import { LayoutContext } from "../Layout";
import { color } from "../../tailwindStyle";
import LectureVideo from "../LectureVideo";
import { useScrollDirection } from "../../hooks/useScrollDirection";

import arrowRightLink from "../../assets/icons/arrow_right_link.svg";
import shareLecture from "../../assets/icons/share_lecture.svg";
import starBlueEmpty from "../../assets/icons/star_blue_empty.svg";
import starBlueFull from "../../assets/icons/star_blue_full.svg";
import defaultProfile2 from "../../assets/icons/default_profile2.svg";
import RecommendLectures from "../shared/RecommendLectures";

const RatingBar = ({ value }) => {
  const ratingBarStyle = css`
    background: linear-gradient(
      to right,
      ${color["blue-base"]} 0 ${parseInt(value?.toFixed(0))}%,
      #ebebeb ${parseInt(value?.toFixed(0))}% 100%
    );
  `;

  return (
    <div
      css={ratingBarStyle}
      className="rounded-[5px] h-[6px] w-full relative"
    ></div>
  );
};

const ReviewPhoto = ({ review, index, reviewImages }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(index);

  return (
    <div>
      <img
        className="object-cover w-full rounded-[5px] aspect-square w-full cursor-pointer"
        src={review}
        alt={`리뷰포토_${index}`}
        onClick={() => setIsOpen(true)}
      />

      {isOpen && (
        <div>
          <Lightbox
            mainSrc={reviewImages[photoIndex]}
            nextSrc={reviewImages[(photoIndex + 1) % reviewImages.length]}
            prevSrc={
              reviewImages[
                (photoIndex + reviewImages.length - 1) % reviewImages.length
              ]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + reviewImages.length - 1) % reviewImages.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % reviewImages.length)
            }
          />
        </div>
      )}
    </div>
  );
};

const Web = ({ lecture, reviewSet }) => {
  const { currentUser } = useGetCurrentUser();
  const { isUserSignedIn } = useUserSignedIn();
  const lectureTabRef = useRef(null);

  const [lectureTabHeight, setLectureTabHeight] = useState(0);
  const [curriculums, setCurriculums] = useState(null);

  useEffect(() => {
    getCurriculums({ lectureId: lecture?.id }).then((response) => {
      setCurriculums(response?.data);
    });

    setLectureTabHeight(lectureTabRef.current?.clientHeight);
  }, []);

  const handleClickReviewWrite = () => {
    if (isUserSignedIn) {
      postEncrypt({
        targetValue: currentUser?.id,
      }).then((response) => {
        postEncrypt({
          targetValue: lecture?.id,
        }).then((res) => {
          location.href = `/reviews/new?user_id=${response?.data}&reviewable_type=Lecture&reviewable_id=${res?.data}`;
        });
      });
    } else {
      Swal.fire({
        title: "로그인이 필요한 서비스입니다.",
        text: "로그인 하시겠습니까?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "로그인",
        cancelButtonText: "취소",
      }).then((result) => {
        if (result.isConfirmed) {
          setReturnSession();
        }
      });
    }
  };

  const tabNavStyle = css`
    z-index: 3;
    &::-webkit-scrollbar {
      display: none;
    }
  `;

  const [isTabSelected, setIsTabSelected] = useState(true);

  const navProps = {
    activeClass:
      "class_top_tab !text-black !font-[700] relative after:absolute after:bottom-0 after:content-[''] after:border-b after:border-b-[2px] after:border-black after:w-full cursor-pointer",
    spy: true,
    offset: -lectureTabHeight,
    className: isTabSelected
      ? "class_top_tab flex items-center justify-center text-xl text-black font-[700] relative after:absolute after:bottom-0 after:content-[''] after:border-b after:border-b-[2px] after:border-black after:w-full cursor-pointer"
      : "class_top_tab text-xl text-gray99 font-[500] flex items-center justify-center cursor-pointer pt-[13px] pb-[10px]",
  };

  const {
    title,
    age_range,
    before_price,
    discount_rate,
    total_time,
    price,
    content_files,
    notice,
    materials,
    promotion_video,
    id,
    desc,
    viewer_type,
  } = lecture;

  return (
    <div>
      {!!promotion_video && (
        <LectureVideo
          lectureId={id}
          title={`promotion_video_${id}`}
          videoUrl={promotion_video}
          style={"mt-[30px]"}
          isStartBtn={true}
        />
      )}

      <div className="py-[30px] border-b border-b-[2px] border-b-[#F9F9F9]">
        <div className="flex items-center justify-between">
          <span className="text-[28px] font-[500]">{title}</span>
        </div>
        {discount_rate !== "0.0" && (
          <div className="flex text-lg mt-[30px]">
            <span className="text-blue-base font-[800] mr-[10px]">
              {Math.round(discount_rate)}%
            </span>
            <span className="text-gray88 line-through font-[500]">
              {addComma(Math.round(before_price))}원
            </span>
          </div>
        )}
        <div className="mt-[5px]">
          <span className="font-[700] text-[32px] mr-1">
            {addComma(Math.round(price))}
          </span>
          <span className="text-[22px] font-[500]">원</span>
        </div>
        {!(
          ["dnav", "etc"].includes(viewer_type) &&
          reviewSet?.meta?.total_count === 0
        ) && (
          <div className="flex mt-[30px]">
            {times(Math.ceil(reviewSet?.meta?.review_avg), constant(0))?.map(
              (item, index) => {
                return (
                  <img
                    key={`star_full_${index}`}
                    src={starBlueFull}
                    alt="채워진파란별"
                  />
                );
              }
            )}
            {times(
              5 - Math.ceil(reviewSet?.meta?.review_avg),
              constant(0)
            )?.map((item, index) => {
              return (
                <img
                  key={`star_empty_${index}`}
                  src={starBlueEmpty}
                  alt="빈파란별"
                />
              );
            })}
            <a
              href={`/reviews?reviewable_id=${lecture?.id}&reviewable_type=Lecture`}
              className="underline text-[14px] font-[500] ml-[10px]"
            >
              리뷰 {reviewSet?.meta?.total_count}
            </a>
          </div>
        )}
      </div>
      {id !== (74 || 80) && (!!age_range || !!total_time || !!materials) && (
        <div className="py-[30px] border-b border-b-[8px] border-b-[#F9F9F9] text-[15px]">
          {viewer_type !== "etc" && (
            <div className="flex">
              <span className="text-gray99 mr-[10px] shrink-0">권장연령</span>
              <span className="font-[600]">{age_range}</span>
            </div>
          )}
          {!["dnav", "etc"].includes(viewer_type) && (
            <div className="flex flex-col">
              <div className="flex mt-[15px]">
                <span className="text-gray99 mr-[10px] shrink-0">강의구성</span>
                <span className="font-[600]">{total_time}</span>
              </div>
              <div className="flex mt-[15px]">
                <span className="text-gray99 mr-[10px] shrink-0">수업자료</span>
                <span className="font-[600]">{materials}</span>
              </div>
            </div>
          )}
        </div>
      )}

      {/* 탭 */}
      <div
        ref={lectureTabRef}
        css={tabNavStyle}
        className={`sticky top-0 w-full grid px-[20px] pb-[2px] bg-white ${
          !["dnav", "etc"].includes(lecture?.viewer_type)
            ? "grid-cols-3"
            : "grid-cols-2"
        }`}
      >
        <Link
          {...navProps}
          onSetInactive={() => setIsTabSelected(true)}
          to="classIntroduce"
        >
          클래스 소개
        </Link>
        <Link
          {...navProps}
          onSetActive={() => setIsTabSelected(false)}
          to="reviews"
          className="class_top_tab text-xl text-gray99 font-[500] flex items-center justify-center cursor-pointer pt-[13px] pb-[10px]"
        >
          리뷰
        </Link>
        {!["dnav", "etc"].includes(lecture?.viewer_type) && (
          <Link
            {...navProps}
            onSetActive={() => setIsTabSelected(false)}
            to="curriculums"
            className="class_top_tab text-xl text-gray99 font-[500] flex items-center justify-center cursor-pointer pt-[13px] pb-[10px]"
          >
            커리큘럼
          </Link>
        )}
      </div>
      <Element name="classIntroduce">
        <div className="flex flex-col">
          {!["dnav", "etc"].includes(lecture?.viewer_type) ? (
            content_files?.map((file) => {
              return file?.file_type === "image" ? (
                <img
                  key={file?.id}
                  className="w-full mx-auto"
                  alt={file?.title}
                  src={file?.file}
                />
              ) : (
                <LectureVideo
                  key={file?.id}
                  lectureId={id}
                  title={file?.title}
                  videoUrl={file?.file}
                  isStartBtn={true}
                />
              );
            })
          ) : (
            <div
              css={css`
                img {
                  width: 100% !important;
                  height: auto !important;
                }
              `}
              dangerouslySetInnerHTML={{ __html: desc }}
            ></div>
          )}
        </div>
        <div className="py-[30px]  flex flex-col border-b border-b-[8px] border-[#F9F9F9]">
          <span className="font-[700] text-gray66 text-[15px]">유의 사항</span>
          <span dangerouslySetInnerHTML={{ __html: notice }}></span>
        </div>
      </Element>

      <Element name="reviews">
        <div className="py-[30px] border-b border-b-[8px] border-b-[#F9F9F9]">
          <div className="flex items-center justify-between ">
            <div className="font-[700] text-[22px] mr-2">💬</div>
            <div className="font-[700] text-[22px] mr-[10px]">리뷰</div>
            {reviewSet?.meta?.total_count !== 0 && (
              <a
                href={`/reviews?reviewable_id=${lecture?.id}&reviewable_type=Lecture`}
              >
                <img src={arrowRightLink} alt="전체리뷰이동" />
              </a>
            )}
            <button
              type="button"
              onClick={handleClickReviewWrite}
              className="ml-auto font-[500] text-[15px] underline text-gray99"
            >
              리뷰 쓰기
            </button>
          </div>
          {reviewSet?.meta?.total_count > 0 ? (
            <div className="mt-[30px] bg-[#FAFAFA] rounded-[10px] w-full py-[25px] grid grid-cols-[3fr_4fr]">
              <div className="flex flex-col justify-center items-center border-r border-r border-[#EEEEEE]">
                <span className="text-[40px] font-[700]">
                  {reviewSet?.meta?.review_avg?.toFixed(1) ?? "0.0"}
                </span>
                <span className="text-[15px] font-[500] text-gray66">
                  ({reviewSet?.meta?.total_count ?? "0.0"})
                </span>
              </div>
              <div className="w-full flex flex-col w-[56%] mx-auto justify-center max-w-[150px]">
                <div className="flex items-center w-full">
                  <span className="font-[700] mr-[10px] shrink-0 text-[13px]">
                    5점
                  </span>
                  <RatingBar
                    value={
                      (reviewSet?.meta?.rating_count["5"] /
                        reviewSet?.meta?.total_count) *
                      100
                    }
                  />
                </div>
                <div className="flex items-center w-full mt-[3px]">
                  <span className="mr-[10px] shrink-0 text-[13px] text-gray66">
                    4점
                  </span>
                  <RatingBar
                    value={
                      (reviewSet?.meta?.rating_count["4"] /
                        reviewSet?.meta?.total_count) *
                      100
                    }
                  />
                </div>
                <div className="flex items-center w-full mt-[3px]">
                  <span className="mr-[10px] shrink-0 text-[13px] text-gray66">
                    3점
                  </span>
                  <RatingBar
                    value={
                      (reviewSet?.meta?.rating_count["3"] /
                        reviewSet?.meta?.total_count) *
                      100
                    }
                  />
                </div>
                <div className="flex items-center w-full mt-[3px]">
                  <span className="mr-[10px] shrink-0 text-[13px] text-gray66">
                    2점
                  </span>
                  <RatingBar
                    value={
                      (reviewSet?.meta?.rating_count["2"] /
                        reviewSet?.meta?.total_count) *
                      100
                    }
                  />
                </div>
                <div className="flex items-center w-full mt-[3px]">
                  <span className="mr-[10px] shrink-0 text-[13px] text-gray66">
                    1점
                  </span>
                  <RatingBar
                    value={
                      (reviewSet?.meta?.rating_count["1"] /
                        reviewSet?.meta?.total_count) *
                      100
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="h-[130px] font-[500] text-lg text-gray99 flex justify-center items-center mt-[30px] bg-[#FAFAFA] rounded-[10px] w-full py-[25px] mb-[40px]">
              아직 리뷰가 없어요 😢
            </div>
          )}
          {/* 포토 리뷰 */}
          {reviewSet?.meta?.images_count !== 0 && (
            <div className="py-[20px] mt-[30px] border-b border-b-2 border-t border-[#F9F9F9]">
              <span className="text-lg font-[700]">포토 리뷰</span>
              <div className="grid grid-cols-7 gap-[16px] mt-[10px]">
                {slice(reviewSet?.meta?.image_urls, 0, 6)?.map(
                  (review, index) => {
                    return (
                      <ReviewPhoto
                        key={`리뷰포토_${index}`}
                        reviewImages={reviewSet?.meta?.image_urls}
                        review={review}
                        index={index}
                      />
                    );
                  }
                )}

                {reviewSet?.meta?.images_count > 6 && (
                  <a
                    href={`/reviews?reviewable_id=${lecture?.id}&reviewable_type=Lecture`}
                    className="flex items-center justify-center font-[700] text-lg text-white relative object-cover w-full aspect-square rounded-[5px] opacity-[60] bg-cover bg-center bg-no-repeat"
                    css={css`
                      background-image: url(${reviewSet?.meta?.image_urls[6]});
                      &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.55);
                        border-radius: 5px;
                      }
                    `}
                  >
                    <span className="z-[2]">
                      + {reviewSet?.meta?.images_count - 6}
                    </span>
                  </a>
                )}
              </div>
            </div>
          )}
          {/* 리뷰 카드 본문 */}
          {reviewSet?.reviews?.length !== 0 && (
            <div>
              {slice(reviewSet?.reviews, 0, 3).map((review) => {
                return (
                  <div className="pt-[20px]" key={`review_${review?.id}`}>
                    <div className="flex flex-col mb-[20px]">
                      <div className="flex items-center justify-between w-full">
                        <div className="flex items-center w-full">
                          <div className="rounded-[50%] w-[30px] h-[30px] mr-[10px]">
                            <img
                              src={review?.user?.avatar?.url || defaultProfile2}
                              alt={`유저프로필_${review?.id}`}
                              className="w-full object-cover rounded-full w-[30px] h-[30px]"
                            />
                          </div>
                          <div className="flex flex-col w-full">
                            <div className="flex items-center justify-between w-full">
                              <div className="text-[13px] font-[700]">
                                {review?.user?.nickname || review?.user?.name}
                              </div>
                              <div className="flex items-center">
                                {times(
                                  Math.ceil(review?.rating),
                                  constant(0)
                                )?.map((item, index) => {
                                  return (
                                    <img
                                      key={`star_full_${index}`}
                                      src={starBlueFull}
                                      alt="채워진파란별"
                                      className="w-[14px]"
                                    />
                                  );
                                })}
                                {times(
                                  5 - Math.ceil(review?.rating),
                                  constant(0)
                                )?.map((item, index) => {
                                  return (
                                    <img
                                      key={`star_empty_${index}`}
                                      src={starBlueEmpty}
                                      alt="빈파란별"
                                      className="w-[14px]"
                                    />
                                  );
                                })}
                              </div>
                            </div>
                            <span className="text-[11px] font-[500] text-[#B2B2B2]">
                              {moment(review?.created_at).format("YYYY-MM-DD")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-[20px]">
                        {review?.images?.length !== 0 && (
                          <div className="relative w-[120px] aspect-square mr-[21px] shrink-0">
                            <ReviewPhoto
                              reviewImages={review?.images}
                              review={review?.images[0]}
                              index={0}
                            />

                            {review?.images?.length > 1 && (
                              <div
                                className="absolute w-[40px] h-[40px] bottom-0 right-0 text-white rounded-tl-[5px] rounded-br-[5px] flex justify-center items-center font-[700]"
                                css={css`
                                  background-color: rgba(0, 0, 0, 0.75);
                                `}
                              >
                                + {review?.images?.length - 1}
                              </div>
                            )}
                          </div>
                        )}
                        <div
                          className="font-[500] line-ellipsis leading-[22px] whitespace-pre-wrap max-h-[112px]"
                          css={css`
                            -webkit-line-clamp: 5;
                          `}
                          dangerouslySetInnerHTML={{ __html: review?.body }}
                        ></div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <a
                href={`/reviews?reviewable_id=${lecture?.id}&reviewable_type=Lecture`}
                className="bg-blue-base text-white pt-[17px] pb-[18px] flex items-center justify-center rounded-[5px] text-sm font-[700]"
              >
                후기 전체보기 ({reviewSet?.meta?.total_count})
              </a>
            </div>
          )}
        </div>
      </Element>

      {!["dnav", "etc"].includes(lecture?.viewer_type) && (
        <Element name="curriculums">
          <div className="pt-[30px] pb-[10px] border-b border-b-[8px] border-b-[#F9F9F9]">
            <div className="text-[22px] font-[700] mb-[30px]">커리큘럼 👀</div>
            <div
              className="flex flex-col"
              css={css`
                .curriculumStyle + .curriculumStyle:before {
                  content: "";
                  margin-bottom: 30px;
                  border-bottom: 1px solid #f9f9f9;
                }
              `}
            >
              {curriculums?.map((curriculum) => {
                return (
                  filter(
                    curriculum?.lessons,
                    (lessonMode) => lessonMode?.mode !== "internal_download"
                  ).length !== 0 && (
                    <div
                      key={`curriculum_${curriculum?.id}`}
                      className="flex flex-col pb-[30px] curriculumStyle"
                      css={css`
                        .lessonStyle + .lessonStyle {
                          margin-top: 10px;
                        }
                      `}
                    >
                      <div className="flex mb-[10px]">
                        <span className="text-xl font-[700]">
                          {curriculum?.chapter_title}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        {filter(
                          curriculum?.lessons,
                          (lessonMode) =>
                            lessonMode?.mode !== "internal_download"
                        )?.map((lesson) => {
                          return (
                            <div
                              className="flex items-center lessonStyle"
                              key={`${curriculum?.id}_${lesson?.id}`}
                            >
                              {lesson?.label && (
                                <div className="font-[900] text-[#0049FF] mr-[10px] shrink-0">
                                  {lesson?.label}
                                </div>
                              )}

                              <div
                                className="font-[500] line-ellipsis text-gray66"
                                css={css`
                                  -webkit-line-clamp: 2;
                                `}
                              >
                                {lesson?.title}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </Element>
      )}

      <RecommendLectures
        lectureId={lecture?.id}
        className="py-[30px] mobile:px-4"
      />
    </div>
  );
};

const Mobile = ({ lecture, reviewSet }) => {
  const { currentUser } = useGetCurrentUser();
  const { isUserSignedIn } = useUserSignedIn();
  const { navHeight, eventBannerState, banners } = useContext(LayoutContext);
  const lectureTabRef = useRef(null);

  const { openDrawer } = useContext(DrawerContext);

  const [lectureTabHeight, setLectureTabHeight] = useState(0);
  const [curriculums, setCurriculums] = useState(null);
  useEffect(() => {
    getCurriculums({ lectureId: lecture?.id }).then((response) => {
      setCurriculums(response?.data);
    });

    setLectureTabHeight(lectureTabRef.current?.clientHeight);
  }, []);

  const { scrollDirection } = useScrollDirection();
  const { setNavHeight, setEventBannerState } = useContext(LayoutContext);
  const [cookies, setCookie] = useCookies(["isEventBannerOpen"]);

  useEffect(() => {
    if (scrollDirection === "down") {
      setEventBannerState(false);
      setLectureTabHeight(0);
      setNavHeight(0);
    } else {
      setEventBannerState(
        cookies["isEventBannerOpen"] === "false" ? false : true
      );
      setLectureTabHeight(lectureTabRef.current.offsetHeight);
      setNavHeight(96);
    }
  }, [scrollDirection]);

  const handleClickReviewWrite = () => {
    if (isUserSignedIn) {
      postEncrypt({
        targetValue: currentUser?.id,
      }).then((response) => {
        postEncrypt({
          targetValue: lecture?.id,
        }).then((res) => {
          location.href = `/reviews/new?user_id=${response?.data}&reviewable_type=Lecture&reviewable_id=${res?.data}`;
        });
      });
    } else {
      Swal.fire({
        title: "로그인이 필요한 서비스입니다.",
        text: "로그인 하시겠습니까?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "로그인",
        cancelButtonText: "취소",
      }).then((result) => {
        if (result.isConfirmed) {
          setReturnSession();
        }
      });
    }
  };

  const tabNavStyle = css`
    top: ${eventBannerState && banners?.length > 0
      ? navHeight - 1 + lectureTabHeight
      : navHeight - 1}px;

    z-index: 3;
    &::-webkit-scrollbar {
      display: none;
    }
  `;

  const [isTabSelected, setIsTabSelected] = useState(true);

  const navProps = {
    activeClass:
      "class_top_tab !text-black !font-[700] relative after:absolute after:bottom-0 after:content-[''] after:border-b after:border-b-[2px] after:border-black after:w-full cursor-pointer",
    spy: true,
    offset: eventBannerState
      ? -(lectureTabHeight + navHeight + 52)
      : -(lectureTabHeight + navHeight),
    className: isTabSelected
      ? "class_top_tab flex items-center justify-center text-sm text-black font-[700] relative after:absolute after:bottom-0 after:content-[''] after:border-b after:border-b-[2px] after:border-black after:w-full cursor-pointer"
      : "class_top_tab text-sm text-gray99 font-[500] flex items-center justify-center pt-[13px] pb-[10px] cursor-pointer",
  };

  const {
    title,
    age_range,
    before_price,
    discount_rate,
    total_time,
    price,
    content_files,
    notice,
    materials,
    promotion_video,
    id,
    viewer_type,
    desc,
  } = lecture;

  return (
    <div>
      {!!promotion_video && (
        <LectureVideo
          videoUrl={promotion_video}
          title={`promotion_video_${id}`}
          lectureId={id}
          isStartBtn={false}
        />
      )}

      <div className="px-4 py-5 border-b border-b-[2px] border-b-[#F9F9F9]">
        <div className="flex items-center justify-between">
          <div className="text-lg font-[500] leading-[25px] mr-[26px]">
            {title}
          </div>
          <button
            className="w-[22px] shrink-0"
            type="button"
            id="mobileExternalSharing"
            onClick={openDrawer}
          >
            <img src={shareLecture} alt="공유하기" />
          </button>
        </div>

        <div className="flex mt-5 text-sm">
          {discount_rate !== "0.0" && (
            <div className="flex flex-col">
              <span className="text-blue-base font-[800] mr-2">
                {Math.round(discount_rate)}%
              </span>

              <span className="text-gray88 line-through font-[500]">
                {addComma(Math.round(before_price))}원
              </span>
            </div>
          )}
        </div>
        <div className="mt-[5px]">
          <span className="font-[700] text-[22px] mr-[2px]">
            {addComma(Math.round(price))}
          </span>
          <span className="text-sm font-[500]">원</span>
        </div>

        {!(
          ["dnav", "etc"].includes(lecture?.viewer_type) &&
          reviewSet?.meta?.total_count === 0
        ) && (
          <div className="flex mt-[20px]">
            {times(Math.ceil(reviewSet?.meta?.review_avg), constant(0))?.map(
              (item, index) => {
                return (
                  <img
                    key={`star_full_${index}`}
                    src={starBlueFull}
                    alt="채워진파란별"
                  />
                );
              }
            )}
            {times(
              5 - Math.ceil(reviewSet?.meta?.review_avg),
              constant(0)
            )?.map((item, index) => {
              return (
                <img
                  key={`star_empty_${index}`}
                  src={starBlueEmpty}
                  alt="빈파란별"
                />
              );
            })}
            <a
              href={`/reviews?reviewable_id=${lecture?.id}&reviewable_type=Lecture`}
              className="underline text-xs font-[500] ml-[10px]"
            >
              리뷰 {reviewSet?.meta?.total_count}
            </a>
          </div>
        )}
      </div>

      {id !== 74 && (!!age_range || !!total_time || !!materials) && (
        <div className="px-4 py-[20px] border-b border-b-[8px] border-b-[#F9F9F9]">
          {viewer_type !== "etc" && (
            <div className="flex text-sm">
              <span className="text-gray99 mr-[10px] shrink-0">권장연령</span>
              <span className="font-[600] ">{age_range}</span>
            </div>
          )}
          {!["dnav", "etc"].includes(viewer_type) && (
            <div className="flex flex-col">
              <div className="flex text-sm mt-[15px]">
                <span className="text-gray99 mr-[10px] shrink-0">강의구성</span>
                <span className="font-[600] ">{total_time}</span>
              </div>
              <div className="flex text-sm mt-[15px]">
                <span className="text-gray99 mr-[10px] shrink-0">수업자료</span>
                <span className="font-[600]">{materials}</span>
              </div>
            </div>
          )}
        </div>
      )}

      {/* 탭 */}
      <div
        ref={lectureTabRef}
        css={tabNavStyle}
        className={`sticky w-full grid px-[20px] pb-[2px] bg-white ${
          !["dnav", "etc"].includes(viewer_type) ? "grid-cols-3" : "grid-cols-2"
        }`}
      >
        <Link
          {...navProps}
          onSetInactive={() => setIsTabSelected(true)}
          to="classIntroduce"
        >
          클래스 소개
        </Link>
        <Link
          onSetActive={() => setIsTabSelected(false)}
          {...navProps}
          className="class_top_tab text-sm text-gray99 font-[500] flex items-center justify-center pt-[13px] pb-[10px] cursor-pointer"
          to="reviews"
        >
          리뷰
        </Link>
        {!["dnav", "etc"].includes(viewer_type) && (
          <Link
            {...navProps}
            onSetActive={() => setIsTabSelected(false)}
            className="class_top_tab text-sm text-gray99 font-[500] flex items-center justify-center pt-[13px] pb-[10px] cursor-pointer"
            to="curriculums"
          >
            커리큘럼
          </Link>
        )}
      </div>
      <Element name="classIntroduce">
        <div className="flex flex-col">
          {!["dnav", "etc"].includes(viewer_type) ? (
            content_files?.map((file) => {
              return file?.file_type === "image" ? (
                <img
                  key={file?.id}
                  className="w-full mx-auto"
                  alt={file?.title}
                  src={file?.file}
                />
              ) : (
                <LectureVideo
                  key={file?.id}
                  videoUrl={file?.file}
                  title={file?.title}
                  lectureId={id}
                  isStartBtn={false}
                />
              );
            })
          ) : (
            <div
              css={css`
                iframe {
                  width: 100%;
                }
              `}
            >
              <div
                css={css`
                  img {
                    width: 100% !important;
                    height: auto !important;
                  }
                `}
                dangerouslySetInnerHTML={{ __html: desc }}
              ></div>
            </div>
          )}
        </div>
        <div className="py-[30px] px-4 flex flex-col border-b border-b-[8px] border-[#F9F9F9]">
          <span className="font-[700] text-gray66 text-[13px]">유의 사항</span>
          <span dangerouslySetInnerHTML={{ __html: notice }}></span>
        </div>
      </Element>

      <Element name="reviews">
        <div className="py-[30px] border-b border-b-[8px] border-b-[#F9F9F9] px-4">
          <div className="flex items-center justify-between">
            <div className="font-[700] text-[22px] mr-[12px]">💬</div>
            <div className="font-[700] text-[22px]">리뷰</div>
            {reviewSet?.meta?.total_count !== 0 && (
              <a
                href={`/reviews?reviewable_id=${lecture?.id}&reviewable_type=Lecture`}
              >
                <img src={arrowRightLink} alt="전체리뷰이동" />
              </a>
            )}
            <button
              type="button"
              onClick={handleClickReviewWrite}
              className="ml-auto font-[500] text-[13px] underline text-gray99"
            >
              리뷰 쓰기
            </button>
          </div>

          {reviewSet?.meta?.total_count > 0 ? (
            <div className="px-4 mt-[20px] bg-[#FAFAFA] rounded-[10px] w-full py-[25px] grid grid-cols-[3fr_4fr]">
              <div className="flex flex-col justify-center items-center border-r border-r border-[#EEEEEE]">
                <span className="text-[34px] font-[700]">
                  {reviewSet?.meta?.review_avg?.toFixed(1) ?? "0.0"}
                </span>
                <span className="text-[13px] font-[500] text-gray66">
                  ({reviewSet?.meta?.total_count ?? "0.0"})
                </span>
              </div>
              <div className="w-full flex flex-col w-[56%] mx-auto justify-center max-w-[150px]">
                <div className="flex items-center w-full">
                  <span className="font-[700] mr-2 shrink-0 text-[11px]">
                    5점
                  </span>
                  <RatingBar
                    value={
                      (reviewSet?.meta?.rating_count["5"] /
                        reviewSet?.meta?.total_count) *
                      100
                    }
                  />
                </div>
                <div className="flex items-center w-full">
                  <span className="mr-2 shrink-0 text-[11px] text-gray66">
                    4점
                  </span>
                  <RatingBar
                    value={
                      (reviewSet?.meta?.rating_count["4"] /
                        reviewSet?.meta?.total_count) *
                      100
                    }
                  />
                </div>
                <div className="flex items-center w-full">
                  <span className="mr-2 shrink-0 text-[11px] text-gray66">
                    3점
                  </span>
                  <RatingBar
                    value={
                      (reviewSet?.meta?.rating_count["3"] /
                        reviewSet?.meta?.total_count) *
                      100
                    }
                  />
                </div>
                <div className="flex items-center w-full">
                  <span className="mr-2 shrink-0 text-[11px] text-gray66">
                    2점
                  </span>
                  <RatingBar
                    value={
                      (reviewSet?.meta?.rating_count["2"] /
                        reviewSet?.meta?.total_count) *
                      100
                    }
                  />
                </div>
                <div className="flex items-center w-full">
                  <span className="mr-2 shrink-0 text-[11px] text-gray66">
                    1점
                  </span>
                  <RatingBar
                    value={
                      (reviewSet?.meta?.rating_count["1"] /
                        reviewSet?.meta?.total_count) *
                      100
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="h-[130px] font-[500] text-sm text-gray99 flex justify-center items-center mt-[20px] bg-[#FAFAFA] rounded-[10px] w-full py-[25px] mb-[30px]">
              아직 리뷰가 없어요 😢
            </div>
          )}

          {/* 포토 리뷰 */}
          {reviewSet?.meta?.images_count !== 0 && (
            <div className="py-[20px] mt-[20px] border-b border-b-2 border-t border-[#F9F9F9]">
              <span className="text-lg font-[700]">포토 리뷰</span>
              <div className="grid grid-cols-4 gap-[8px] mt-[10px]">
                {slice(reviewSet?.meta?.image_urls, 0, 3)?.map(
                  (review, index) => {
                    return (
                      <div key={`리뷰포토_${index}`}>
                        <ReviewPhoto
                          key={`리뷰포토_${index}`}
                          reviewImages={reviewSet?.meta?.image_urls}
                          review={review}
                          index={index}
                        />
                      </div>
                    );
                  }
                )}
                {reviewSet?.meta?.images_count > 3 && (
                  <a
                    href={`/reviews?reviewable_id=${lecture?.id}&reviewable_type=Lecture`}
                    className="flex items-center justify-center font-[700] text-lg text-white relative object-cover w-full aspect-square rounded-[5px] opacity-60 bg-cover bg-center bg-no-repeat"
                    css={css`
                      background-image: url(${reviewSet?.meta?.image_urls[3]});
                      &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.55);
                        border-radius: 5px;
                      }
                    `}
                  >
                    <span className="z-[2]">
                      + {reviewSet?.meta?.images_count - 3}
                    </span>
                  </a>
                )}
              </div>
            </div>
          )}

          {/* 리뷰 카드 본문 */}
          {reviewSet?.reviews?.length !== 0 && (
            <div>
              {slice(reviewSet?.reviews, 0, 3).map((review) => {
                return (
                  <div className="pt-[20px]" key={`review_${review?.id}`}>
                    <div className="flex flex-col mb-[20px]">
                      <div className="flex items-center justify-between w-full">
                        <div className="flex items-center w-full">
                          <div className="rounded-[50%] w-[30px] h-[30px] mr-[10px]">
                            <img
                              src={review?.user?.avatar?.url || defaultProfile2}
                              alt={`유저프로필_${review?.id}`}
                              className="w-full object-cover rounded-full w-[30px] h-[30px]"
                            />
                          </div>
                          <div className="flex flex-col w-full">
                            <div className="flex items-center justify-between w-full">
                              <div className="text-[13px] font-[700]">
                                {review?.user?.nickname || review?.user?.name}
                              </div>
                              <div className="flex items-center">
                                {times(
                                  Math.ceil(review?.rating),
                                  constant(0)
                                )?.map((item, index) => {
                                  return (
                                    <img
                                      key={`star_full_${index}`}
                                      src={starBlueFull}
                                      alt="채워진파란별"
                                      className="w-[12px]"
                                    />
                                  );
                                })}
                                {times(
                                  5 - Math.ceil(review?.rating),
                                  constant(0)
                                )?.map((item, index) => {
                                  return (
                                    <img
                                      key={`star_empty_${index}`}
                                      src={starBlueEmpty}
                                      alt="빈파란별"
                                      className="w-[12px]"
                                    />
                                  );
                                })}
                              </div>
                            </div>
                            <span className="text-[11px] font-[500] text-[#B2B2B2]">
                              {moment(review?.created_at).format("YYYY-MM-DD")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-[10px]">
                        {review?.thumb_images?.length !== 0 && (
                          <div className="relative w-[56px] h-[56px] mr-[10px] shrink-0">
                            <ReviewPhoto
                              reviewImages={review?.images}
                              review={review?.images[0]}
                              index={0}
                            />

                            {review?.images?.length > 1 && (
                              <div
                                className="absolute w-[25px] h-[25px] bottom-0 right-0 text-white rounded-tl-[5px] rounded-br-[5px] flex justify-center items-center font-[700] text-xs"
                                css={css`
                                  background-color: rgba(0, 0, 0, 0.75);
                                `}
                              >
                                + {review?.images?.length - 1}
                              </div>
                            )}
                          </div>
                        )}
                        <div
                          className="text-[13px] font-[500] line-ellipsis leading-[15px] whitespace-pre-wrap max-h-[80px]"
                          css={css`
                            -webkit-line-clamp: 5;
                          `}
                          dangerouslySetInnerHTML={{ __html: review?.body }}
                        ></div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <a
                href={`/reviews?reviewable_id=${lecture?.id}&reviewable_type=Lecture`}
                className="bg-blue-base text-white pt-[17px] pb-[18px] flex items-center justify-center rounded-[5px] text-sm font-[700]"
              >
                후기 전체보기 ({reviewSet?.meta?.total_count})
              </a>
            </div>
          )}
        </div>
      </Element>

      {!["dnav", "etc"].includes(viewer_type) && (
        <Element name="curriculums">
          <div className="px-4 pt-[30px] pb-[10px] border-b border-b-[8px] border-b-[#F9F9F9]">
            <div className="text-[22px] font-[700] mb-[30px]">커리큘럼 👀</div>
            <div
              className="flex flex-col"
              css={css`
                .curriculumStyle + .curriculumStyle:before {
                  content: "";
                  margin-bottom: 20px;
                  border-bottom: 1px solid #f9f9f9;
                }
              `}
            >
              {curriculums?.map((curriculum) => {
                return (
                  filter(
                    curriculum?.lessons,
                    (lessonMode) => lessonMode?.mode !== "internal_download"
                  ).length !== 0 && (
                    <div
                      key={`curriculum_${curriculum?.id}`}
                      className="flex flex-col pb-[20px] curriculumStyle"
                      css={css`
                        .lessonStyle + .lessonStyle {
                          margin-top: 10px;
                        }
                      `}
                    >
                      <div className="flex mb-[10px]">
                        <span className="text-sm font-[700]">
                          {curriculum?.chapter_title}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        {filter(
                          curriculum?.lessons,
                          (lessonMode) =>
                            lessonMode?.mode !== "internal_download"
                        )?.map((lesson) => {
                          return (
                            <div
                              className="flex items-center lessonStyle"
                              key={`${curriculum?.id}_${lesson?.id}`}
                            >
                              {lesson?.label && (
                                <div className="font-[900] text-[#0049FF] mr-[10px] text-[13px] shrink-0">
                                  {lesson?.label}
                                </div>
                              )}
                              <div
                                className="text-[13px] font-[500] line-ellipsis text-gray66"
                                css={css`
                                  -webkit-line-clamp: 2;
                                `}
                              >
                                {lesson?.title}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </Element>
      )}

      <RecommendLectures lectureId={lecture?.id} className="py-[30px] px-4" />
    </div>
  );
};

const ContentRenewal = ({ lecture }) => {
  const deviceCheck = useDeviceCheck();
  const contentStyle = css`
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      max-height: 7000px;
    }
  `;

  const [reviews, setReviews] = useState();
  useEffect(() => {
    getReviews({ targetId: lecture?.id, targetType: "Lecture" }).then(
      (response) => {
        setReviews(response?.data);
      }
    );
  }, []);

  return (
    <div css={contentStyle}>
      {deviceCheck !== "mobile" ? (
        <Web lecture={lecture} reviewSet={reviews} />
      ) : (
        <Mobile lecture={lecture} reviewSet={reviews} />
      )}
    </div>
  );
};

export default ContentRenewal;
