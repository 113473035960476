import { css } from "@emotion/react";

import { setReturnSession } from "../../utils";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";

import defaultProfile from "../../assets/icons/default_profile.svg";
import logoMain from "../../assets/images/nav_logo.png";
import activeRedDot from "../../assets/icons/active_red_dot.svg";

const NavWeb = () => {
  const { currentUser, isUserLoading } = useGetCurrentUser();
  const { isUserSignedIn } = useUserSignedIn();

  // const navMenus = [
  //   {
  //     id: "goDnav",
  //     position: 1,
  //     name: "D.Nav",
  //     link: "/lectures/dnav",
  //     className: "web:pl-0 tablet:pl-0",
  //     pointActive: true,
  //   },
  //   {
  //     id: "onedayClass",
  //     position: 2,
  //     name: "원데이클래스",
  //     link: "/oneday_boards",
  //     className: "",
  //     pointActive: true,
  //   },
  //   {
  //     id: "goClass",
  //     position: 3,
  //     name: "클래스",
  //     link: "/lectures/kids",
  //     className: "",
  //     pointActive: false,
  //   },
  //   {
  //     id: "goCooingklePackage",
  //     position: 4,
  //     name: "쿠킹 잉글리쉬",
  //     link: "/cooingkle_package",
  //     className: "",
  //     pointActive: false,
  //   },
  //   {
  //     id: "goTamgeulAllkill",
  //     position: 5,
  //     name: "올킬 글쓰기",
  //     link: "/tamgeul_allkill",
  //     className: "",
  //     pointActive: false,
  //   },
  //   {
  //     id: "goMakeyourdream",
  //     position: 6,
  //     name: "꿈업일치",
  //     link: "/makeyourdream",
  //     className: "",
  //     pointActive: false,
  //   },
  //   {
  //     id: "goEvent",
  //     position: 7,
  //     name: "이벤트",
  //     link: "/boards?board_type=event",
  //     className: "",
  //     pointActive: false,
  //   },
  //   {
  //     id: "goNotice",
  //     position: 8,
  //     name: "공지사항",
  //     link: "/boards?board_type=notice",
  //     className: "",
  //     pointActive: false,
  //   },
  // ];
  const navMenus = [
    {
      id: "goDnav",
      position: 1,
      name: "D.Nav",
      link: "/lectures/118",
      className: "web:pl-0 tablet:pl-0",
      pointActive: true,
    },
    {
      id: "goMindOffice",
      position: 2,
      name: "마음 우체국",
      link: "/lectures/80",
      className: "",
      pointActive: false,
    },
    {
      id: "goClass",
      position: 3,
      name: "VOD 클래스",
      link: "/lectures/kids",
      className: "",
      pointActive: false,
    },

    {
      id: "goEvent",
      position: 6,
      name: "이벤트",
      link: "/boards?board_type=event",
      className: "",
      pointActive: false,
    },
    {
      id: "goNotice",
      position: 7,
      name: "공지사항",
      link: "/boards?board_type=notice",
      className: "",
      pointActive: false,
    },
  ];

  return (
    <div
      id="nav"
      className="flex flex-col justify-between w-full max-w-[1230px] mx-auto px-[15px] leading-[1.2]"
    >
      <div className="flex items-center justify-between w-full navbar-brand h-[70px]">
        <a href="/" className="block w-full max-w-[216px]">
          <img src={logoMain} alt="메인로고" />
        </a>

        <div className="flex justify-between navbar-menu">
          {!isUserLoading &&
            (isUserSignedIn ? (
              <a href="/mypage/lectures_kids" className="flex items-center">
                <span className="shrink-0 mr-3 pt-[6px]">
                  <strong>{currentUser?.nickname || currentUser?.name}</strong>{" "}
                  님
                </span>
                <div className="flex items-center">
                  <div
                    className="w-10 h-10 bg-center bg-cover rounded-full shadow-login_input"
                    css={css`
                      background-image: url(${currentUser?.avatar?.url ||
                      currentUser?.avatar?.url ||
                      defaultProfile});
                    `}
                  ></div>
                </div>
              </a>
            ) : (
              <button
                type="button"
                onClick={() => setReturnSession()}
                className="all_login_bar text-gray99 bg-[#EEEEEE] text-sm font-[700] px-5 flex items-center justify-center rounded-[16px] py-2"
              >
                로그인
              </button>
            ))}
        </div>
      </div>
      <div className="flex items-center h-[50px]">
        {navMenus?.map((navMenu) => {
          return (
            <a
              className={`all_category px-5 flex shrink-0 items-center justify-center font-[700] ${navMenu?.className}`}
              href={navMenu?.link}
              key={navMenu?.position}
              id={navMenu?.id}
            >
              <div className="relative">
                <span>{navMenu?.name}</span>
                {navMenu?.pointActive && (
                  <img
                    className="w-[5px] h-[5px] absolute top-[-4px] right-[-7px]"
                    src={activeRedDot}
                    alt={`붉은점_${navMenu?.position}`}
                  />
                )}
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default NavWeb;
